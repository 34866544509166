import React, { useState } from 'react';
import { Form, Icon, Button, Message } from 'semantic-ui-react';
import { Market, GalleryPicture } from 'mo-shared';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { Link } from 'react-router-dom';

const db = firebase.firestore();

const cloudinary = (window as any).cloudinary;

const GalleryAddPicture: React.FC<{
    url?: string;
    logo?: boolean;
    editable?: boolean;
    onChange: (arg: GalleryPicture | null) => void;
}> = ({ url, logo, editable, onChange }) => {
    const openWidget = () => {
        const widget = cloudinary.createUploadWidget(
            {
                cloud_name: 'dczpge5xk',
                upload_preset: logo ? 'SiteLogo' : 'GalleryPicture',
                sources: ['local', 'camera', 'facebook'],
            },
            (error: Error, result: any) => {
                console.log(error, result);
                if (error) {
                    console.log('Could not upload image', error);
                    return;
                } else {
                    const { event, info } = result;
                    if (event === 'success') {
                        const { secure_url, thumbnail_url } = info;
                        onChange({ url: secure_url, thumbnail_url });
                    }
                }
            }
        );
        widget.open();
    };

    return (
        <div className="gallery-add-image-container picture-container framed input-field">
            <div
                onClick={(e) => {
                    if (!url || editable) openWidget();
                }}
            >
                {url ? (
                    <img src={url} alt="Gallery" />
                ) : (
                    <Icon name="add" size="huge" />
                )}
            </div>
        </div>
    );
};

const GalleryPictureWidget: React.FC<{
    url: string;
    desciption?: string;
    onDelete: () => void;
}> = ({ url, desciption, onDelete }) => {
    return (
        <div className="gallery-image-container framed">
            <img src={url} alt={desciption} />
            <Button type="button" circular icon="close" onClick={onDelete} />
        </div>
    );
};

export const AddNewPlace: React.FC = () => {
    const [state, setState] = useState<Partial<Market>>({
        country: 'България',
        city: 'София',
        hidden: true,
        active: false,
        claimable: true,
        gallery: [],
    });
    console.debug('AddNewPlace state', state);
    const [status, setStatus] = useState({
        completed: false,
        pending: false,
        error: '',
    });
    const [newObjectID, setNewObjectID] = useState('');

    const onChange = (e: any, { name, value }: any) =>
        setState({ ...state, [name]: value });

    const v = (name: keyof Market, required = true) => ({
        value: state[name] as any,
        required,
        size: 'big' as any,
        disabled: status.pending,
        name,
        onChange,
    });
    const onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setStatus({ ...status, pending: true, error: '' });
        db.collection('markets')
            .add(state)
            .then((doc) => {
                setStatus({ ...status, completed: true, pending: false });
                setNewObjectID(doc.id);
            })
            .catch((err) =>
                setStatus({ ...status, error: err.toString(), pending: false })
            );
    };
    return (
        <div>
            <h2>Добави обект</h2>
            <Form
                onSubmit={onSubmit}
                loading={status.pending}
                success={status.completed}
                error={!!status.error}
            >
                <Form.Input
                    fluid
                    label="Име"
                    {...v('name')}
                    autoComplete="new-password"
                />
                <Form.Group>
                    <Form.Input width={3} label="Държава" {...v('country')} />
                    <Form.Input width={3} label="Град" {...v('city')} />
                    <Form.Input width={10} label="Адрес" {...v('address')} />
                </Form.Group>
                <Form.TextArea label="Описание" {...v('description', false)} />
                <h4>Лого</h4>
                <Form.Field inline>
                    <GalleryAddPicture
                        logo
                        editable
                        url={state.logoURL || ''}
                        onChange={(arg) =>
                            setState({
                                ...state,
                                logoURL: arg?.url || undefined,
                            })
                        }
                    />
                </Form.Field>
                <h4>Снимки</h4>
                <Form.Field inline>
                    <div className="gallery">
                        {state.gallery?.map((gl) => (
                            <GalleryPictureWidget
                                url={gl.url}
                                onDelete={() =>
                                    setState((state) => ({
                                        ...state,
                                        gallery: state.gallery!.filter(
                                            (p) => p !== gl
                                        ),
                                    }))
                                }
                            />
                        ))}
                        <GalleryAddPicture
                            onChange={(obj) => {
                                if (obj)
                                    setState((state) => ({
                                        ...state,
                                        gallery: [
                                            ...(state.gallery || []),
                                            obj,
                                        ],
                                    }));
                            }}
                        />
                    </div>
                </Form.Field>
                <Message success>
                    Готово! <Link to={`/place/${newObjectID}`} />
                </Message>
                <Message error>{status.error}</Message>
                <Form.Button primary>Добави</Form.Button>
            </Form>
        </div>
    );
};
