import React, {
    useState,
    useEffect,
    useCallback,
    SyntheticEvent,
    useRef,
} from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
} from 'react-router-dom';
import {
    Loader,
    Image,
    Form,
    Button,
    Message,
    Menu,
    Icon,
    Dropdown,
    Container,
    Card,
    List,
    Label,
    Modal,
    Segment,
} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import './utils/init_firebase';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebaseui/dist/firebaseui.css';
import dayjs from 'dayjs';
import 'dayjs/locale/bg';
import calendar from 'dayjs/plugin/calendar';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
    Terms,
    Footer,
    PickupRange,
    OrderDetails,
    usePickupRange,
    STATUS,
    Market,
} from 'mo-shared';
import {
    FillEmailAndName,
    Login,
    EmailNotVerified,
    SetOrChangePhoneNumber,
    Intro,
    AddNewPlace,
} from './components';
dayjs.locale('bg');
dayjs.extend(localizedFormat);
dayjs.extend(calendar);

const PREVIEW_MODE = window.location.search === '?preview';

const calendarLocale = {
    sameDay: '[Днес]', // The same day ( Today at 2:30 AM )
    nextDay: '[Утре]', // The next day ( Tomorrow at 2:30 AM )
    nextWeek: 'dddd', // The next week ( Sunday at 2:30 AM )
    lastDay: '[Вчера]', // The day before ( Yesterday at 2:30 AM )
    lastWeek: 'DD.MM.YYYY', // Last week ( Last Monday at 2:30 AM )
    sameElse: 'DD.MM.YYYY', // Everything else ( 7/10/2011 )
};

if (process.env.NODE_ENV === 'production') {
    firebase.analytics();
}
if (process.env.REACT_APP_SIMULATE_CLOUD) {
    console.log('Set using simulator for functions - http://localhost:5001');
    firebase.functions().useFunctionsEmulator('http://localhost:5001');
}
firebase.auth().useDeviceLanguage();
const db = firebase.firestore();
const functions = firebase.app().functions('europe-west3');

const useAuth = () => {
    console.log('useAuth');
    const [user, setUser] = useState<firebase.User | null | undefined>();
    useEffect(() => {
        return firebase.auth().onIdTokenChanged((user) => {
            console.log('Loaded user', user);
            setUser(user);
        });
    }, []);
    return user;
};

const useCallFirebaseFunction = (name: string) => {
    const [status, setStatus] = useState({
        completed: false,
        loading: false,
        error: '',
    });

    const call = useCallback(
        (data: any) => {
            setStatus({ loading: true, error: '', completed: false });
            return functions
                .httpsCallable(name)(data)
                .then(() =>
                    setStatus({ loading: false, error: '', completed: true })
                )
                .catch((err) => {
                    console.log('error calling function', name, err);
                    setStatus({
                        loading: false,
                        error: 'Получила се е грешка при изпълнение',
                        completed: false,
                    });
                });
        },
        [name]
    );
    return { status, call };
};

const Centered: React.FC = ({ children }) => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        }}
    >
        {children}
    </div>
);

const range2str = (range: PickupRange) => {
    const [date, time] = range.begin.split(' ');
    const [date2, time2] = range.end.split(' ');

    const datePretty = dayjs(date).calendar(dayjs(), calendarLocale);
    return date === date2
        ? `${datePretty} / ${time} - ${time2}`
        : `${date} / ${time} - ${date2} ${time2}`;
};
const timestamp2str = (timestamp: string) => dayjs(timestamp).format('L LTS');

//todo
const status2str = (status: string) => status;

const reIsAdminChange = /^ADMIN_/;

const OrderHistory: React.FC<{ history: any }> = ({ history }) => {
    return (
        <div>
            <h4>История</h4>
            <List relaxed="very" divided>
                {Object.keys(history).map((timestamp) => {
                    const { type, data, message } = history[timestamp];
                    const { pickup_range, details, status } = data;
                    return (
                        <List.Item key={timestamp}>
                            <List.Content>
                                <List.Header>
                                    {reIsAdminChange.test(type)
                                        ? 'Магазин'
                                        : 'Аз'}
                                </List.Header>
                                <List.Description>
                                    {timestamp2str(timestamp)}
                                    {!!status && (
                                        <div>Статус: {status2str(status)}</div>
                                    )}
                                    {!!pickup_range && (
                                        <div>
                                            Време за изпълнение:{' '}
                                            {range2str(pickup_range)}
                                        </div>
                                    )}
                                    {!!details && <div>Заявка: {details}</div>}
                                    {!!message && (
                                        <div className="order-history-message">
                                            {message}
                                        </div>
                                    )}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    );
                })}
            </List>
        </div>
    );
};

const MarketLogoName: React.FC<{ name: string; logo: string }> = ({
    name,
    logo,
}) => (
    <div className="market-logo-name">
        <Image src={logo} size="mini" verticalAlign="middle" />
        &nbsp; &nbsp;
        {name}
    </div>
);

const OrderView: React.FC<{ order: OrderDetails }> = ({ order }) => {
    const [message, setMessage] = useState('');

    const {
        id,
        market_name = '',
        market_logoURL = '',
        status,
        details,
        history,
        pickup_range,
    } = order;

    const [{ pending, error, completed }, setStatus] = useState({
        pending: false,
        error: '',
        completed: false,
    });
    const submitMessage = () => {
        const order = {
            id,
            message,
        };
        console.log('Send order update', order);
        setStatus({ pending: true, error: '', completed: false });
        functions
            .httpsCallable('updateOrder')(order)
            .then(() => {
                setStatus({ pending: false, error: '', completed: true });
                setMessage('');
            })
            .catch((err) => {
                console.log('error updating order', err);
                setStatus({
                    pending: false,
                    error: 'Получила се е грешка при изпълнение',
                    completed: false,
                });
            });
    };
    return (
        <div>
            <h2>
                <MarketLogoName name={market_name} logo={market_logoURL} />
            </h2>
            <h4>{status2str(status)}</h4>
            <h4>{range2str(pickup_range)}</h4>
            <pre>{details}</pre>
            <OrderHistory history={history} />
            {(status === STATUS.WAITING_CLIENT_RESPONSE ||
                status === STATUS.CLIENT_RESPONSE) && (
                <>
                    <Form.TextArea
                        className="order-message"
                        placeholder="Съобщение към магазина"
                        onChange={(e, { value }) => setMessage(value as string)}
                        value={message}
                    />
                    <Button
                        onClick={submitMessage}
                        loading={pending}
                        disabled={!message}
                        primary
                    >
                        Изпрати
                    </Button>
                </>
            )}
            <br />
            {error && (
                <Message negative attached="bottom">
                    <Message.Header>{error}</Message.Header>
                    <p>Моля опитайте по-късно.</p>
                </Message>
            )}
            {completed && (
                <Message positive attached="bottom">
                    <Message.Header>
                        Съобщението е изпратено успешно.
                    </Message.Header>
                </Message>
            )}
        </div>
    );
};

const MyOrders = () => {
    const [orders, setOrders] = useState<OrderDetails[] | null>(null);
    const uid = firebase.auth().currentUser!.uid;

    const { id: selectedOrderId } = useParams();

    useEffect(() => {
        const unsub = db
            .collection('orders')
            .where('user_uid', '==', uid)
            .onSnapshot((snap) => {
                const list: OrderDetails[] = [];
                snap.forEach((doc) => {
                    list.push({
                        id: doc.id,
                        ...doc.data(),
                    } as OrderDetails);
                });
                console.log('markets', list);
                list.sort((l, r) => (l.created < r.created ? 1 : -1));

                setOrders(list);
            });
        return unsub;
    }, [uid]);

    const selectedOrder =
        selectedOrderId && orders
            ? orders.find((order) => order.id === selectedOrderId)
            : null;

    return (
        <div className="orders-list">
            {!orders && <Loader active />}
            {!!selectedOrder && <OrderView order={selectedOrder} />}
            {!!orders && !selectedOrderId && (
                <List divided relaxed="very" selection>
                    {orders.map((order) => (
                        <List.Item
                            key={order.id}
                            as={Link}
                            to={`/my-orders/${order.id}`}
                            className={
                                order.status === 'COMPLETED'
                                    ? 'completed'
                                    : undefined
                            }
                        >
                            <List.Content>
                                <List.Header className="order-header">
                                    <MarketLogoName
                                        name={order.market_name || ''}
                                        logo={order.market_logoURL || ''}
                                    />

                                    <div>{range2str(order.pickup_range)}</div>
                                    <Label>{status2str(order.status)}</Label>
                                </List.Header>
                                <List.Description>
                                    <div>{timestamp2str(order.created)}</div>
                                    <br />
                                    <div>{order.details}</div>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            )}
        </div>
    );
};

const MarketsList = () => {
    const [markets, setMarkets] = useState<Market[] | null>(null);

    useEffect(() => {
        const unsub = db.collection('markets').onSnapshot((snap) => {
            let list: Market[] = [];
            snap.forEach((doc) => {
                list.push({
                    id: doc.id,
                    ...doc.data(),
                } as Market);
            });
            console.log('markets', list);

            if (!PREVIEW_MODE) {
                list = list.filter((m) => !m.hidden);
            }

            setMarkets(list);
        });
        return unsub;
    }, []);

    return (
        <div className="markets-list">
            {!markets && <Loader active />}
            {!!markets && (
                <Card.Group centered>
                    {markets.map((m) => (
                        <Card key={m.id}>
                            <Card.Content as={Link} to={`/place/${m.id}`}>
                                <Image
                                    floated="right"
                                    size="tiny"
                                    src={m.logoURL}
                                />
                                <Card.Header>{m.name}</Card.Header>
                                <Card.Meta>
                                    {m.city}, {m.address}
                                </Card.Meta>
                                {/* <Card.Description>
                                    {m.city}, {m.address}
                                </Card.Description> */}
                            </Card.Content>
                            <Card.Content extra>
                                {/* <Link to={`/place/${m.id}`}>
                                    <Icon name="shop" />
                                    Поръчай онлайн
                                </Link> */}
                                <div className="ui two buttons">
                                    <Button
                                        basic
                                        className="action-button"
                                        // color="green"
                                        as={Link}
                                        to={`/place/${m.id}`}
                                    >
                                        Поръчай онлайн
                                    </Button>
                                </div>
                            </Card.Content>
                        </Card>
                    ))}
                </Card.Group>
            )}
            {/* {!!markets && (
                <List divided animated>
                    {markets.map(m => (
                        <List.Item key={m.id} as={Link} to={`/place/${m.id}`}>
                            <Image
                                src={m.logoURL}
                                size="mini"
                                verticalAlign="middle"
                            />
                            <List.Content>
                                <List.Header>{m.name}</List.Header>
                                <List.Description>
                                    {m.city}, {m.address}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            )} */}
        </div>
    );
};

const MarketNotFound = () => (
    <div className="market-not-found">
        За съжаление тази страница не е намерена :(
    </div>
);

const initialOrderData = {
    details: '',
    pickup_range: '',
};
const orderPlaceholder = `Примерна заявка: 
2 кисели млека Верея 2%
1 бял хляб
1 кашкавал Маджаров
6 яйца
`;

const OrderForm = ({
    marketId: market_id,
    userUid: user_uid,
}: {
    marketId: string;
    userUid: string;
}) => {
    const [status, setStatus] = useState({
        completed: false,
        loading: false,
        error: '',
    });
    const [data, setData] = useState(initialOrderData);
    const [showEmptyFieldsErrors, setShowEmptyFieldsErrors] = useState(false);

    const { matchingRange, pickupRangeOptions } = usePickupRange();

    const submit = useCallback(() => {
        if (true || window.confirm('Готови ли сте да пуснете заявката?')) {
            let { details, pickup_range: pickupRangeVal } = data;
            const pickup_range = matchingRange[pickupRangeVal];
            if (!pickup_range || !details) {
                setShowEmptyFieldsErrors(true);
                return;
            } else {
                setShowEmptyFieldsErrors(false);
            }
            const order = {
                market_id,
                // user_uid,
                pickup_range,
                details,
                // status: 'NEW',
                // timestamp: firebase.firestore.FieldValue.serverTimestamp()
            };
            console.log('Send order', order);
            setStatus({ loading: true, error: '', completed: false });
            // db.collection('orders-incoming')
            //     .add(order)
            functions
                .httpsCallable('newOrder')(order)
                .then(() =>
                    setStatus({ loading: false, error: '', completed: true })
                )
                .catch((err) => {
                    console.log('error placing order', err);
                    setStatus({
                        loading: false,
                        error: 'Получила се е грешка при изпълнение',
                        completed: false,
                    });
                });
        }
    }, [market_id, data, matchingRange]);

    // const clear = () => {
    //     if (true || window.confirm('Искате да изчистите формата?')) {
    //         setData(initialOrderData);
    //     }
    // };
    const onChange = (e: SyntheticEvent, { name, value }: any) => {
        setData({ ...data, [name]: value });
    };

    const isDisabled = status.loading || status.completed;

    return (
        <div className="order-form">
            <Form onSubmit={submit} className="fluid">
                <Form.TextArea
                    disabled={isDisabled}
                    // required
                    error={showEmptyFieldsErrors && !data.details}
                    label="Заявка"
                    name="details"
                    value={data.details}
                    onChange={onChange}
                    placeholder={orderPlaceholder}
                ></Form.TextArea>
                <Form.Dropdown
                    options={pickupRangeOptions}
                    selection
                    // required
                    error={showEmptyFieldsErrors && !data.pickup_range}
                    disabled={isDisabled}
                    name="pickup_range"
                    value={data.pickup_range || undefined}
                    selectOnBlur={false}
                    selectOnNavigation={false}
                    onChange={onChange}
                    label="Предпочитано време за взимане на покупките"
                />
                {status.error && (
                    <Message negative attached="bottom">
                        <Message.Header>{status.error}</Message.Header>
                        {/* <p>Моля опитайте по-късно.</p> */}
                    </Message>
                )}
                {status.completed && (
                    <Message positive attached="bottom">
                        <Message.Header>
                            Заявката е изпратена успешно.
                        </Message.Header>
                        <p>Моля проверете статуса й по-късно.</p>
                    </Message>
                )}
                <Button
                    primary
                    type="submit"
                    loading={status.loading}
                    disabled={isDisabled}
                    content="Изпрати"
                    icon="paper plane"
                ></Button>
                {/* <Button type="button" onClick={clear} disabled={isDisabled}>
                    Изчисти формата
                </Button> */}
            </Form>
        </div>
    );
};

const saveClaimed = (market_id: string) => {
    localStorage.setItem(`object-claimed-${market_id}`, Date.now().toString());
};
const checkClaimed = (market_id: string) => {
    return !!localStorage.getItem(`object-claimed-${market_id}`);
};

const ClaimBusiness: React.FC<{ market_id: string; onClose: () => void }> = ({
    market_id,
    onClose,
}) => {
    const user = useAuth();
    const [state, setState] = useState({
        name: user?.displayName || '',
        uic: '',
    });

    const { status, call: claimBusiness } = useCallFirebaseFunction(
        'claimBusiness'
    );
    if (!user) return <Loader active />;

    const onChange = (e: any, { name, value }: any) => {
        setState({ ...state, [name]: value });
    };

    const onSubmit = async () => {
        if (!user) return;
        const { name, uic } = state;
        if (name && name !== user.displayName) {
            await user.updateProfile({ displayName: name });
        }
        const data = { market_id, uic: uic || null };

        return claimBusiness(data).then(() => saveClaimed(market_id));
    };
    return (
        <Modal open closeIcon onClose={onClose}>
            <Modal.Header>Заявяване на бизнес</Modal.Header>
            <Modal.Content>
                <Form
                    onSubmit={onSubmit}
                    loading={status.loading}
                    success={status.completed}
                    error={!!status.error}
                >
                    <Form.Input value={user.email} label="Имейл" />
                    <Form.Input value={user.phoneNumber} label="Телефон" />
                    <Form.Input
                        required
                        name="name"
                        value={state.name}
                        onChange={onChange}
                        disabled={status.completed}
                        label="Име"
                    />
                    <Form.Input
                        value={state.uic || ''}
                        label="ЕИК"
                        name="uic"
                        disabled={status.completed}
                        onChange={onChange}
                    />
                    <Message success>
                        Заявката е подадена успешно. Ще ви известим допълнително
                        на вашия електронен адрес
                    </Message>
                    <Message error>{status.error}</Message>
                    <Form.Button primary disabled={status.completed}>
                        Заяви
                    </Form.Button>
                </Form>
            </Modal.Content>
        </Modal>
    );
};

const MarketView = () => {
    const { id } = useParams();
    const [market, setMarket] = useState<Market | null>(null);
    const [notFound, setNotFound] = useState(false);

    const isClaimed = useRef(false);

    const user = useAuth();

    useEffect(() => {
        const unsub = db
            .collection('markets')
            .doc(id)
            .onSnapshot((doc) => {
                console.log('Market info loaded', doc);
                if (!doc.exists) {
                    setNotFound(true);
                    return;
                }

                if (doc.data()?.claimable) {
                    isClaimed.current = checkClaimed(id!);
                }

                setMarket({
                    id: doc.id,
                    ...doc.data(),
                } as Market);
            });

        return unsub;
    }, [id]);

    const [showLogin, setShowLogin] = useState(false);
    const onClickRegister = (e: any) => setShowLogin(true);

    const [claimBusiness, setClaimBusiness] = useState(false);

    return (
        <div className="market-view-holder">
            {!market && !notFound && <Loader active />}
            {!market && notFound && <MarketNotFound />}
            {claimBusiness && !!id && (
                <ClaimBusiness
                    market_id={id}
                    onClose={() => setClaimBusiness(false)}
                />
            )}
            {showLogin && !user && (
                <Modal open basic closeIcon onClose={() => setShowLogin(false)}>
                    <Login signInSuccessUrl={window.location.href} />
                </Modal>
            )}
            {!!market && (
                <Segment className="market-view" padded>
                    <h1>
                        <span className="market-name">{market.name}</span>
                        <Image
                            verticalAlign="middle"
                            floated="right"
                            size="tiny"
                            src={market.logoURL}
                        />
                    </h1>
                    <div className="address">
                        <Icon name="map marker alternate" /> {market.city},{' '}
                        {market.address}
                    </div>
                    {market.claimable &&
                        (!isClaimed.current ? (
                            <a
                                className="claim-business"
                                href="#claim"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setClaimBusiness(true);
                                }}
                            >
                                <Icon name="shield" /> Заяви този бизнес
                            </a>
                        ) : (
                            <div className="claim-business">
                                <Icon name="shield" /> Изпратена заявка, изчаква
                                потвърждение.
                            </div>
                        ))}
                    <p className="market-description">{market.description}</p>
                    {market.active ? (
                        <>
                            {user ? (
                                <OrderForm marketId={id!} userUid={user!.uid} />
                            ) : (
                                <div className="unregistered">
                                    <hr />
                                    <Button
                                        basic
                                        color="blue"
                                        onClick={onClickRegister}
                                    >
                                        Регистрирай се
                                    </Button>
                                    &nbsp;или&nbsp;&nbsp;
                                    <Button
                                        basic
                                        color="blue"
                                        onClick={onClickRegister}
                                    >
                                        Влез в профила
                                    </Button>{' '}
                                    за да продължиш с поръчката.
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="online-orders-not-supported">
                            Онлайн заявки временно не се приемат.
                        </div>
                    )}
                </Segment>
            )}
        </div>
    );
};

const Navbar = () => {
    const user = useAuth();

    const signout = () => firebase.auth().signOut();

    return (
        <>
            <Menu secondary fixed="top" id="navbar" inverted size="massive">
                <Container>
                    <Menu.Item name="Магазини" as={Link} to="/" />
                    {!!user && (
                        <Menu.Item
                            name="Моите поръчки"
                            as={Link}
                            to="/my-orders"
                        />
                    )}
                    <Menu.Menu position="right">
                        {user === null && (
                            <Menu.Item
                                name="Регистрация"
                                as={Link}
                                to="/login"
                            />
                        )}
                        {!!user && (
                            <Dropdown item icon="user outline">
                                <Dropdown.Menu>
                                    <Dropdown.Item disabled>
                                        {user.email || user.phoneNumber}
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={signout}>
                                        <Icon name="log out" />
                                        Излез
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </Menu.Menu>
                </Container>
            </Menu>
            <div style={{ width: '100%', height: 65 }} />
        </>
    );
};

const scrollToJoin = (e: any) => {
    e.preventDefault();
    window.document
        .getElementById('join-as-store')
        ?.scrollIntoView({ behavior: 'smooth' });
};
const StoreAdminEntrance = () => {
    return (
        <div className="new-markets-info">
            <Button
                basic
                as="a"
                href="#join-as-store"
                color="blue"
                className="question"
                onClick={scrollToJoin}
            >
                Подходящо ли е за моя бизнес? <Icon name="arrow down" />
            </Button>
            <Message id="join-as-store">
                <p>
                    Платформата е подходяща за магазини, аптеки, сладкарници и
                    подобни бизнеси.
                </p>
                <p>
                    Целта е да се автоматизира процесът на онлайн заявката на
                    стоките от крайните клиенти, разглеждане и подготвяне на
                    поръчката с последващото предаване или доставка на клиента.
                </p>
                <p>
                    <a href={'mailto:info@bookinggood.net'}>Пишете ни</a> ако
                    имате интерес да използвате платформата за вашия бизнес.
                </p>
                <p>
                    Споделяйте за платформата и на магазините, където бихте
                    желали да я ползвате като клиенти.
                </p>
                <div className="actions">
                    <Button
                        as="a"
                        icon="mail outline"
                        content="Свържи се с нас"
                        primary
                        href="mailto:info@bookinggood.net"
                    />

                    <Button
                        basic
                        color="blue"
                        as="a"
                        href="https://store-admin.bookinggood.net/"
                        icon="sign-in"
                        content="Към админ панела"
                    />

                    <Button
                        color="green"
                        as={Link}
                        to="/add"
                        icon="add"
                        content="Добави обект"
                    />
                </div>
            </Message>
        </div>
    );
};

const AuthProtected: React.FC = ({ children }) => {
    const user = useAuth();
    if (user === undefined) return <Loader active />;
    if (user === null) return <Login signInSuccessUrl={window.location.href} />;
    if (!user.phoneNumber)
        return (
            <Centered>
                <SetOrChangePhoneNumber />
            </Centered>
        );
    if (!user.email || !user.emailVerified)
        return (
            <Centered>
                {!user.email ? <FillEmailAndName /> : <EmailNotVerified />}
            </Centered>
        );
    return <>{children}</>;
};

const mainContainerStyle = { flex: '1 0 auto' };
const expandHeight = { minHeight: 'calc(100vh - 125px)' };

function App() {
    return (
        <Router>
            <Navbar />
            <Container style={mainContainerStyle}>
                <Switch>
                    <Route path="/place/:id">
                        {/* <AuthProtected> */}
                        <MarketView />
                        {/* </AuthProtected> */}
                    </Route>
                    <Route path={['/my-orders/:id', '/my-orders']}>
                        <AuthProtected>
                            <MyOrders />
                        </AuthProtected>
                    </Route>
                    <Route path={'/add'}>
                        <AuthProtected>
                            <AddNewPlace />
                        </AuthProtected>
                    </Route>
                    <Route path="/login">
                        <Login />
                    </Route>
                    <Route path="/terms">
                        <Terms />
                    </Route>
                    <Route path="/">
                        <div style={expandHeight}>
                            <Intro />
                            <MarketsList />
                        </div>
                        <StoreAdminEntrance />
                    </Route>
                </Switch>
            </Container>
        </Router>
    );
}

ReactDOM.render(<Footer />, document.getElementById('footer'));

export default App;
