import React from 'react';

const Tiles: React.FC<{ progress: number }> = ({ progress }) => {
    const colors = ['#f9b821', '#e43918', '#8bbe35', '#643388', '#09a5e1'];
    return (
        <div className="tiles">
            {Array(5)
                .fill(1)
                .map((_, idx) => (
                    <div
                        key={idx}
                        style={{
                            borderColor:
                                idx <= progress ? colors[idx] : undefined,
                        }}
                    >
                        {' '}
                    </div>
                ))}
        </div>
    );
};

export const Intro = () => (
    <div className="intro">
        {[
            'Заявете онлайн продукти в магазина',
            'Изберете време за получаване',
            'Изчакайте потвърждение на поръчката',
            'Отидете за да платите и да вземете вашата поръчка',
            'Готово!',
        ].map((text, idx) => (
            <div key={idx}>
                <Tiles progress={idx} />
                <div className="content">
                    <div className="digit">{idx + 1}</div>
                    <div className="text">{text}</div>
                </div>
                <img src={`/walkthrough/0${idx + 1}.svg`} alt={text} />
            </div>
        ))}
    </div>
);
