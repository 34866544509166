import React, { useState, useEffect } from 'react';
import { Form, Segment, Message, Button } from 'semantic-ui-react';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

const ui = new firebaseui.auth.AuthUI(firebase.auth());

export const Login = ({
    signInSuccessUrl = '/',
}: {
    signInSuccessUrl?: string;
}) => {
    const signInOptions: any = [];
    // if (phone || allOptions)
    signInOptions.push({
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        defaultCountry: 'BG',
    });
    // if (email || allOptions) {
    // signInOptions.push({
    //     provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    //     customParameters: {
    //         prompt: 'select_account'
    //     }
    // });
    // signInOptions.push(firebase.auth.EmailAuthProvider.PROVIDER_ID);
    // }

    useEffect(() => {
        // if (!auth.currentUser){
        ui.start('#firebaseui-auth-container', {
            signInSuccessUrl,
            signInOptions,
            tosUrl: window.location.origin + '/terms',
            privacyPolicyUrl: 'https://www.iubenda.com/privacy-policy/89587853',
        });
        return () => ui.reset();
    }, [signInSuccessUrl, signInOptions]);

    return (
        <div className="login-container">
            {/* <Segment className="login-container-inner"> */}
            <div id="firebaseui-auth-container" />
            {/* </Segment> */}
        </div>
    );
};

const ContinueWithGoogleButton = () => {
    const [pending, setPending] = useState(false);
    const [error, setError] = useState('');

    const onClick = () => {
        setPending(true);
        return firebase
            .auth()
            .currentUser!.linkWithRedirect(
                new firebase.auth.GoogleAuthProvider()
            )
            .catch((err) => setError(err.toString()));
    };

    if (error) {
        return <Message negative>{error}</Message>;
    }
    return (
        <Form.Button
            type="button"
            basic
            icon="google"
            content="Продължи с Google"
            color="blue"
            loading={pending}
            onClick={onClick}
        />
    );
};

export const ProfileInfoForm = () => {
    const [passError, setPassError] = useState('');
    const [data, setData] = useState<{
        email: string;
        password: string;
        verify_password: string;
        name: string;
    }>({} as any);
    const [status, setStatus] = useState({ pending: false, error: '' });
    console.log('status', status);

    const onSubmit = async (e: any) => {
        e.preventDefault();
        if (!data.password || data.password.length < 6) {
            setPassError('Паролата трябва да е дълга поне 6 символа');
            return;
        } else if (data.password !== data.verify_password) {
            setPassError('Паролите не съвпадат');
            return;
        }

        setPassError('');
        setStatus({ pending: true, error: '' });

        const { name, email, password } = data;
        try {
            const user = firebase.auth().currentUser!;
            await user.updateProfile({ displayName: name });
            await user
                .linkWithCredential(
                    firebase.auth.EmailAuthProvider.credential(email, password)
                )
                .catch((err) => {
                    if (
                        err.code === 'auth/requires-recent-login' &&
                        user.phoneNumber
                    )
                        return user
                            .reauthenticateWithPhoneNumber(
                                user.phoneNumber,
                                new firebase.auth.RecaptchaVerifier(
                                    window.document.getElementById('recaptcha')
                                )
                            )
                            .then((confirmationResult) => {
                                const code = window.prompt(
                                    'Моля въведете получения 6-цифрен код'
                                );
                                return confirmationResult.confirm(code || '');
                            })
                            .then(() =>
                                user.linkWithCredential(
                                    firebase.auth.EmailAuthProvider.credential(
                                        email,
                                        password
                                    )
                                )
                            );
                    throw err;
                });
            await user.sendEmailVerification();
            setStatus({ pending: false, error: '' });
            window.location.reload();
        } catch (err) {
            console.log('Error linking with email', err);
            setStatus({ pending: false, error: err.message || err.toString() });
        }
    };

    const onChange = (e: any, { name, value }: any) => {
        setData({ ...data, [name]: value as string });
    };

    return (
        <Form onSubmit={onSubmit}>
            <Form.Input
                required
                type="text"
                name="name"
                placeholder="Име"
                onChange={onChange}
            />
            <Form.Input
                required
                type="email"
                name="email"
                placeholder="Имейл"
                onChange={onChange}
            />
            <Form.Input
                required
                error={!!passError}
                type="password"
                name="password"
                placeholder="Парола"
                onChange={onChange}
            />
            <Form.Input
                required
                error={passError || false}
                type="password"
                name="verify_password"
                placeholder="Повторете парола"
                onChange={onChange}
            />
            <Form.Button
                primary
                type="submit"
                content="Продължи"
                loading={status.pending}
                // disabled={(data.password?.length || 0) < 6}
            />
            {!!status.error && <Message negative>{status.error}</Message>}
            <div id="recaptcha" />
        </Form>
    );
};

export const FillEmailAndName = () => {
    return (
        <Segment className="email-and-name-form">
            <h3>Почти сме готови</h3>
            <h5>Имаме нужда и от вашия имейл адрес</h5>
            <br />
            <ContinueWithGoogleButton />
            <hr />
            <ProfileInfoForm />
        </Segment>
    );
};

export const EmailNotVerified = () => {
    const [resent, setResent] = useState(false);
    const [pending, setPending] = useState(false);
    const user = firebase.auth().currentUser!;
    const resend = async () => {
        setPending(true);
        try {
            await user.sendEmailVerification();
            setResent(true);
        } catch (err) {
            alert(err.toString());
        } finally {
            setPending(false);
        }
    };
    return (
        <Segment>
            <div
                style={{
                    lineHeight: '3em',
                    fontSize: '1.1em',
                    textAlign: 'center',
                }}
            >
                <div>
                    Изпратили сме линк за верификация на вашия електронен адрес{' '}
                    <strong>{user.email}</strong>.
                </div>
                <div>
                    Проверете в Спам папката в случай, че не намирате писмото.
                </div>
                <br />
                {!resent && (
                    <Button
                        primary
                        content="Изпрати наново"
                        onClick={resend}
                        loading={pending}
                    />
                )}
                {resent && <div>Изпратен повторно</div>}
            </div>
        </Segment>
    );
};

export class SetOrChangePhoneNumber extends React.Component {
    state = {
        phoneNumber: firebase.auth().currentUser!.phoneNumber || '',
        errorVerify: null,
        verificationCode: '',
        errorConfirm: null,
        confirmResult: null,
        inProgress: false,
    } as any;

    verify = () => {
        let { phoneNumber } = this.state;
        if (!phoneNumber || phoneNumber[0] !== '+' || phoneNumber.length < 8) {
            return this.setState({
                errorVerify:
                    'Phone number seems invalid, should be with country code like +359888000111',
            });
        }
        let user = firebase.auth().currentUser!;
        this.setState(
            { errorVerify: null, inProgress: true, showRecaptcha: true },
            () => {
                user.linkWithPhoneNumber(
                    phoneNumber,
                    new firebase.auth.RecaptchaVerifier('recaptcha-verifier')
                )
                    .then((confirmResult) => this.setState({ confirmResult }))
                    .catch((err) => {
                        this.setState({ errorVerify: err.message });
                        alert(err.message);
                    })
                    .then(() =>
                        this.setState({
                            inProgress: false,
                            showRecaptcha: false,
                        })
                    );
            }
        );
    };
    confirm = () => {
        let { confirmResult, verificationCode } = this.state;
        // let { onDone } = this.props
        this.setState({ errorConfirm: null, inProgress: true });
        console.log('Confirm with code', verificationCode);
        confirmResult!
            .confirm(verificationCode)
            .then(() => {
                console.log('Confirmed');
                this.setState({ confirmResult: null, inProgress: false });
                // onDone()
                window.location.reload();
            })
            .catch((err: any) => {
                this.setState({ errorConfirm: err.message, inProgress: false });
                alert(err.message);
            });
    };
    // unlink = () => {
    //     let { onDone } = this.props
    //     let user = firebase.auth().currentUser
    //     user.unlink(firebase.auth.PhoneAuthProvider.PROVIDER_ID)
    //         .then(() => onDone())
    // }
    // componentWillReceiveProps(props) {
    //     let { phoneNumber } = props
    //     if (phoneNumber !== this.state.phoneNumber)
    //         this.setState({ phoneNumber })
    // }
    componentDidMount() {
        // const { language } = this.props
        firebase.auth().languageCode = 'bg';
    }
    render() {
        // let { showLabels } = this.props
        let user = firebase.auth().currentUser!;
        let {
            phoneNumber,
            verificationCode,
            confirmResult,
            errorVerify,
            errorConfirm,
            inProgress,
            showRecaptcha,
        } = this.state;
        const onSubmit = (e: any) => {
            e.preventDefault();
            confirmResult ? this.confirm() : this.verify();
        };
        const showConfirm = !!confirmResult;
        const showVerify = !confirmResult && phoneNumber !== user.phoneNumber;
        // const showUnlink = !!this.props.phoneNumber

        return (
            <Segment>
                <Form className="change-phone-form" onSubmit={onSubmit}>
                    {
                        <div>
                            {confirmResult
                                ? 'Моля въведете получения код'
                                : 'Моля въведете вашия телефонен номер. Ще получите СМС с код за потвърждение'}
                        </div>
                    }
                    <br />
                    <div>
                        {confirmResult ? (
                            <Form.Input
                                type="number"
                                placeholder="6 цифрен код"
                                value={verificationCode}
                                onChange={(e, { value }) =>
                                    this.setState({ verificationCode: value })
                                }
                                error={errorConfirm}
                            />
                        ) : (
                            <Form.Input
                                type="tel"
                                placeholder="примерно +359888000111"
                                value={phoneNumber || ''}
                                onChange={(e, { value }) =>
                                    this.setState({
                                        phoneNumber: value || null,
                                    })
                                }
                                error={errorVerify}
                            />
                        )}
                        <p className="error">{errorVerify}</p>
                        <p className="error">{errorConfirm}</p>
                    </div>
                    <div>
                        {showConfirm && (
                            <Button
                                disabled={
                                    !verificationCode ||
                                    verificationCode.length < 6 ||
                                    inProgress
                                }
                            >
                                Потвърди
                            </Button>
                        )}
                        {showVerify && (
                            <Button disabled={!phoneNumber || inProgress}>
                                Верифицирай
                            </Button>
                        )}
                        {/* {showUnlink && <Button type="button" onClick={this.unlink}><Trans>Unlink</Trans></Button>} */}
                    </div>
                    {showRecaptcha && (
                        <div
                            id="recaptcha-verifier"
                            style={{ marginTop: 5 }}
                        ></div>
                    )}
                </Form>
            </Segment>
        );
    }
}
